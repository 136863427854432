<template>
  <section>
    <ButtonCreate v-if="isCreateButtonVisible" class="create-button" route-name="notification_new" />
    <Preloader v-if="callingAPI" />
    <NotificationTable
      :items="list"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="onPageChange"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import NotificationTable from '@/components/table/NotificationTable'
import ButtonCreate from '@/components/shared/ButtonCreate'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NotificationListView',
  components: {
    NotificationTable,
    Preloader,
    ButtonCreate
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_NOTIFICATION_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.$store.getters['user/hasPermission'](this.requiredPermissions.createButton)
    },
    totalCount () {
      return this.$store.getters['notificationService/totalCount']
    },
    page () {
      return this.$store.getters['notificationService/page']
    },
    list () {
      return this.$store.getters['notificationService/list']
    }
  },
  methods: {
    async onPageChange (page) {
      this.$store.commit('notificationService/setPage', page)
      await this.$store.dispatch('notificationService/fetch')
    }
  },
  async created () {
    await this.$store.dispatch('notificationService/fetch')
  }
}
</script>
<style scoped lang="scss">
.create-button {
  width: max-content;
  margin-left: auto;
  margin-bottom: 1rem;
}
</style>
